import React from "react"
import css from "@styled-system/css"

import { Box, Carousel, H6, Image, Text } from "components"

const Item = ({ image, alt, caption, count, ratio }) => (
  <Box pr={[4, 5]}>
    <H6 children={count < 9 ? "0" + (count + 1) : count + 1} mb={4} />
    <Image
      src={image}
      alt={alt}
      css={css({
        width: [ratio * 50 + "vw", ratio * 400, null, ratio * 512],
        height: [`50vw`, 400, null, 512],
      })}
    />
    <Text children={caption} mt={2} fontSize={[0, 1]} />
  </Box>
)

const Gallery = ({ images }) => (
  <Carousel
    children={images.map((image, index) => {
      if (image.gallery_image && image.gallery_image.url) {
        return (
          <Item
            image={image.gallery_image}
            alt={image.gallery_image.alt}
            caption={image.caption}
            count={index}
            ratio={image.gallery_image.fluid.aspectRatio}
            key={image.caption + index}
          />
        )
      } else {
        return null
      }
    })}
    settings={{
      variableWidth: true,
      infinite: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            initialSlide: 0,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 640,
          settings: {
            initialSlide: 0,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }}
  />
)

export default Gallery
