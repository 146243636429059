import React from "react"
import css from "@styled-system/css"

import { Box, Flex, Text, Wrapper } from "components"

const MetaItem = ({ label, children }) => (
  <Box>
    <Text children={label} fontSize={0} />
    <Text children={children} />
  </Box>
)

const Features = ({
  community,
  model,
  bedrooms,
  bathrooms,
  surface,
  moveInDate,
  price,
}) => (
  <Wrapper pt={[4, 5]} pb={[4, 5]}>
    <Flex
      flexWrap="wrap"
      css={css({
        mx: [-2, -4],
        my: -2,
        "> *": { width: ["50%", "initial"], px: [2, 4], py: 2 },
      })}
    >
      {community && <MetaItem label={`Community`} children={community} />}
      {model && <MetaItem label={`Model`} children={model} />}
      {bedrooms && <MetaItem label={`Bedrooms`} children={bedrooms} />}
      {bathrooms && <MetaItem label={`Bathrooms`} children={bathrooms} />}
      {surface && (
        <MetaItem
          label={`Finished Square Footage`}
          children={parseInt(surface).toLocaleString() + " SF"}
        />
      )}
      {moveInDate && (
        <MetaItem label={`Move-in Timeline`} children={moveInDate} />
      )}
      {price && (
        <MetaItem
          label={`Price`}
          children={"$" + parseInt(price).toLocaleString()}
        />
      )}
    </Flex>
  </Wrapper>
)

export default Features
