import React, { useEffect } from "react"
import { graphql } from "gatsby"
import css from "@styled-system/css"

import {
  AspectRatio,
  Box,
  H1,
  H2,
  Image,
  CallToAction,
  Wrapper,
  SEO,
} from "components"
import { usePreviewData } from "utils"

import Features from "./_features"
import Gallery from "./_gallery"
import Meta from "./_meta"
import Showhome from "./_showhome"

function QuickPossessionTemplate({ data, setTagColor }) {
  // Merge preview data with static query if possible
  const MERGED_DATA = usePreviewData(data)

  let showhome
  data.showhomes.nodes.forEach(searchShowhome => {
    if (
      MERGED_DATA.prismicMoveInReadyHome.data.community &&
      MERGED_DATA.prismicMoveInReadyHome.data.community.document &&
      MERGED_DATA.prismicMoveInReadyHome.data.community.document.data
    ) {
      if (searchShowhome.data.community && searchShowhome.data.community.uid) {
        if (
          searchShowhome.data.community.uid ===
          MERGED_DATA.prismicMoveInReadyHome.data.community.document.uid
        ) {
          showhome = searchShowhome
        }
      }
    }
  })

  useEffect(() => {
    setTagColor(
      MERGED_DATA.prismicMoveInReadyHome.data.community &&
        MERGED_DATA.prismicMoveInReadyHome.data.community.document &&
        MERGED_DATA.prismicMoveInReadyHome.data.community.document &&
        MERGED_DATA.prismicMoveInReadyHome.data.community.document.data
          .community_color
        ? MERGED_DATA.prismicMoveInReadyHome.data.community.document.data
            .community_color
        : "brand.teal.0"
    )
  }, [])
  return (
    <>
      <SEO
        title={{
          text:
            (MERGED_DATA.prismicMoveInReadyHome.data.name &&
              MERGED_DATA.prismicMoveInReadyHome.data.name.text) +
            (MERGED_DATA.prismicMoveInReadyHome.data.community &&
            MERGED_DATA.prismicMoveInReadyHome.data.community.document &&
            MERGED_DATA.prismicMoveInReadyHome.data.community.document
              ? ` in ` +
                (MERGED_DATA.prismicMoveInReadyHome.data.community.document.data
                  .name &&
                  MERGED_DATA.prismicMoveInReadyHome.data.community.document
                    .data.name.text)
              : "") +
            ` | Partners Development Group`,
        }}
        image={
          MERGED_DATA.prismicMoveInReadyHome.data.gallery &&
          MERGED_DATA.prismicMoveInReadyHome.data.gallery[0] &&
          MERGED_DATA.prismicMoveInReadyHome.data.gallery[0].gallery_image &&
          MERGED_DATA.prismicMoveInReadyHome.data.gallery[0].gallery_image
            .url &&
          MERGED_DATA.prismicMoveInReadyHome.data.gallery[0].gallery_image
        }
      />
      <Wrapper pt={[5, 6]}>
        <Box position="relative">
          {MERGED_DATA.prismicMoveInReadyHome.data.name && (
            <H2 as="h1" pt={64}>
              {MERGED_DATA.prismicMoveInReadyHome.data.name.text}
            </H2>
          )}
          {MERGED_DATA.prismicMoveInReadyHome.data.community &&
            MERGED_DATA.prismicMoveInReadyHome.data.community.document &&
            MERGED_DATA.prismicMoveInReadyHome.data.community.document && (
              <H1 as="h2" mt={[12, 3]} fontWeight="bold">
                {MERGED_DATA.prismicMoveInReadyHome.data.community.document.data
                  .name &&
                  MERGED_DATA.prismicMoveInReadyHome.data.community.document
                    .data.name.text}
                {MERGED_DATA.prismicMoveInReadyHome.data.community.document.data
                  .location.document.data.name &&
                  ", " +
                    MERGED_DATA.prismicMoveInReadyHome.data.community.document
                      .data.location.document.data.name}
              </H1>
            )}
          {MERGED_DATA.prismicMoveInReadyHome.data.sticker && (
            <Box position="absolute" top={0} right={[32, 64]} width="15%">
              <AspectRatio ratio="1:1">
                <Image
                  src={MERGED_DATA.prismicMoveInReadyHome.data.sticker}
                  css={css({
                    height: "100%",
                  })}
                />
              </AspectRatio>
            </Box>
          )}
        </Box>
      </Wrapper>
      <Meta
        community={
          MERGED_DATA.prismicMoveInReadyHome.data.community &&
          MERGED_DATA.prismicMoveInReadyHome.data.community.document &&
          MERGED_DATA.prismicMoveInReadyHome.data.community.document &&
          MERGED_DATA.prismicMoveInReadyHome.data.community.document.data
            .name &&
          MERGED_DATA.prismicMoveInReadyHome.data.community.document.data.name
            .text
        }
        model={MERGED_DATA.prismicMoveInReadyHome.data.floorplan}
        bedrooms={MERGED_DATA.prismicMoveInReadyHome.data.bedrooms}
        bathrooms={MERGED_DATA.prismicMoveInReadyHome.data.bathrooms}
        surface={MERGED_DATA.prismicMoveInReadyHome.data.sq_footage}
        moveInDate={MERGED_DATA.prismicMoveInReadyHome.data.move_in_date}
        price={MERGED_DATA.prismicMoveInReadyHome.data.price}
      />
      <Gallery images={MERGED_DATA.prismicMoveInReadyHome.data.gallery} />
      {MERGED_DATA.prismicMoveInReadyHome.data.features &&
        MERGED_DATA.prismicMoveInReadyHome.data.features[0] &&
        MERGED_DATA.prismicMoveInReadyHome.data.features[0].feature && (
          <Features
            features={MERGED_DATA.prismicMoveInReadyHome.data.features}
            bg={
              MERGED_DATA.prismicMoveInReadyHome.data.community &&
              MERGED_DATA.prismicMoveInReadyHome.data.community.document &&
              MERGED_DATA.prismicMoveInReadyHome.data.community.document &&
              MERGED_DATA.prismicMoveInReadyHome.data.community.document.data
                .community_color
                ? MERGED_DATA.prismicMoveInReadyHome.data.community.document
                    .data.community_color
                : "brand.teal.0"
            }
          />
        )}
      {showhome &&
        MERGED_DATA.prismicMoveInReadyHome.data.community &&
        MERGED_DATA.prismicMoveInReadyHome.data.community.document &&
        MERGED_DATA.prismicMoveInReadyHome.data.community.document &&
        MERGED_DATA.prismicMoveInReadyHome.data.community.document.data
          .sales_simplicity_id && (
          <Showhome
            title={showhome.data.title}
            type={showhome.data.showhome_category}
            image={showhome.data.image}
            hours={showhome.data.hours}
            address={showhome.data.address}
            addressLink={showhome.data.address_link}
            addressCoordinates={showhome.data.address_coordinates}
            headshots={showhome.data.sales_teams_headshots}
            names={showhome.data.sales_teams_names}
            roles={showhome.data.sales_teams_roles}
            email={showhome.data.sales_team_email}
            phone={showhome.data.sales_team_phone}
            community={showhome.data.community}
            salesSimplicityId={
              MERGED_DATA.prismicMoveInReadyHome.data.community &&
              MERGED_DATA.prismicMoveInReadyHome.data.community.document &&
              MERGED_DATA.prismicMoveInReadyHome.data.community.document &&
              MERGED_DATA.prismicMoveInReadyHome.data.community.document.data
                .sales_simplicity_id
            }
            homeTitle={
              MERGED_DATA.prismicMoveInReadyHome.data.name &&
              MERGED_DATA.prismicMoveInReadyHome.data.name.text
            }
          />
        )}
      <CallToAction
        style={MERGED_DATA.prismicMoveInReadyHome.data.closing_block_style}
        align={MERGED_DATA.prismicMoveInReadyHome.data.closing_block_side}
        pattern={MERGED_DATA.prismicMoveInReadyHome.data.closing_block_pattern}
        image={MERGED_DATA.prismicMoveInReadyHome.data.closing_block_image}
        heading={MERGED_DATA.prismicMoveInReadyHome.data.closing_block_title}
        text={MERGED_DATA.prismicMoveInReadyHome.data.closing_block_description}
        button={
          MERGED_DATA.prismicMoveInReadyHome.data.closing_block_button_text
        }
        link={MERGED_DATA.prismicMoveInReadyHome.data.closing_block_button_link}
        bg={
          MERGED_DATA.prismicMoveInReadyHome.data.community &&
          MERGED_DATA.prismicMoveInReadyHome.data.community.document &&
          MERGED_DATA.prismicMoveInReadyHome.data.community.document &&
          MERGED_DATA.prismicMoveInReadyHome.data.community.document.data
            .community_color
            ? MERGED_DATA.prismicMoveInReadyHome.data.community.document.data
                .community_color
            : "brand.teal.0"
        }
      />
    </>
  )
}

export const query = graphql`
  query MoveInQuery($uid: String!) {
    prismicMoveInReadyHome(uid: { eq: $uid }) {
      prismicId
      uid
      data {
        image {
          url
          alt
          fluid(maxWidth: 1200) {
            ...GatsbyPrismicImageFluid
            aspectRatio
          }
        }
        sticker {
          fluid(maxWidth: 768) {
            ...GatsbyPrismicImageFluid
          }
          url
          alt
        }
        name {
          text
        }
        community {
          document {
            ... on PrismicCommunity {
              id
              uid
              data {
                sales_simplicity_id
                community_color
                location {
                  document {
                    ... on PrismicLocation {
                      data {
                        name
                      }
                    }
                  }
                }
                name {
                  text
                }
              }
            }
          }
        }
        floorplan
        bedrooms
        bathrooms
        sq_footage
        move_in_date
        price
        features {
          feature
        }
        gallery {
          gallery_image {
            url
            alt
            fluid(maxWidth: 1200) {
              ...GatsbyPrismicImageFluid
              aspectRatio
            }
          }
          caption
        }
        closing_block_image {
          url
          alt
          fluid(maxWidth: 1200) {
            ...GatsbyPrismicImageFluid
          }
        }
        closing_block_video {
          url
        }
        closing_block_title {
          text
        }
        closing_block_description {
          html
        }
        closing_block_button_text
        closing_block_button_link {
          link_type
          url
        }
        closing_block_style
        closing_block_pattern
        closing_block_side
      }
    }
    showhomes: allPrismicShowhome {
      nodes {
        data {
          title {
            text
          }
          showhome_category
          address {
            text
          }
          address_coordinates {
            latitude
            longitude
          }
          address_link {
            url
          }
          hours {
            html
          }
          sales_teams_headshots {
            headshot {
              url
              alt
              fluid(maxWidth: 160) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
          sales_teams_names {
            text
          }
          sales_teams_roles
          sales_team_email
          sales_team_phone
          community {
            uid
          }
        }
      }
    }
  }
`

export default QuickPossessionTemplate
