import React from "react"
import css from "@styled-system/css"

import { Box, ColorMode, H1, Text, Wrapper } from "components"

const Features = ({ features, bg }) => (
  <ColorMode mode="dark">
    <Wrapper as="section" pt={[4, 5]} bg={bg} flush>
      <Box py={[5, null, 6]} px={[3, 4, 5, 6]}>
        <H1 children={`Features`} mb={[3, 4]} />
        <Box
          as="ul"
          css={css({
            m: -1,
            p: 0,
            pl: 4,
            listStyle: "disc",
            color: "text",
            columnCount: [1, 2],
            columnGap: "64px",
          })}
        >
          {features.map((feature, index) => {
            if (feature.feature) {
              return (
                <Text
                  as="li"
                  fontSize={[1, 2, 3]}
                  css={css({
                    position: "relative",
                    display: "block",
                  })}
                  key={`feature` + index}
                >
                  <Box
                    position="absolute"
                    top="0.525em"
                    left="-1.25em"
                    width="0.5em"
                    height="0.5em"
                    border="1px solid"
                    borderColor="text"
                    borderRadius={999}
                    opacity={0.5}
                  />
                  {feature.feature}
                </Text>
              )
            } else {
              return null
            }
          })}
        </Box>
      </Box>
    </Wrapper>
  </ColorMode>
)

export default Features
